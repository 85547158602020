import commonAction from 'actions/commonAction';
import { getUserProfile } from 'actions/user/getUserProfile';
import { IUser } from 'api/generated/models';
import UsersApi from 'api/generated/UsersApi';
import { objectToPatchOperationsArray } from 'api/utilities';

export const PATCH_USER_ACTION = 'PATCH_USER';

export const patchUser = (
    userId: string | undefined,
    year: number,
    user: Partial<IUser>,
    isCurrent: boolean,
    toastSuccessMessage?: string,
    shouldThrow = false
) => {

    const undefinedFields = ['ichraShoppingConfigurationOverride','wagePlusShoppingConfigurationOverride'];
    const hasUndefinedFields = undefinedFields.some(field => field in user);

    const userPatch = hasUndefinedFields ? objectToPatchOperationsArray(user, false, true) : objectToPatchOperationsArray(user);

    return commonAction(
        async () => new UsersApi().patchUser(userId, userPatch),
        PATCH_USER_ACTION,
        {
            toastSuccessMessage,
            additionalDispatchData: { isCurrent },
            nonSuccessHook: ({ dispatch, response }) => {
                dispatch(getUserProfile(userId, isCurrent, year));
                if (shouldThrow) {
                    throw new Error(`${response.error.response?.data}`);
                }
            },
            toastErrorMessage: (response) =>
                `Unable to update user. ${response.error.response?.data}`,
        }
    );
};
